import { Section } from "./styled";

export const Header = () => {
  return (
    <Section>
      <h2>João Victor</h2>
      <a href="">Sobre Mim</a>
      <a href="">Projetos</a>
      <a href="">Contato</a>
    </Section>
  );
};
